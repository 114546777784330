import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from '../Constants';
import { target } from './AuthorTarget.module.scss';

const AuthorTarget = ({ name, configuration }) => (
    <div
        className={`${target}`}
        data-test={testAttributes.AUTHORTARGET_CONTAINER}
    >
        {`${configuration.prefix} `}
        <span data-test={testAttributes.AUTHORTARGET_NAME} itemProp="author">{name}</span>
    </div>
);
AuthorTarget.propTypes = {
    /** name, Name of author of the tooltip */
    name: PropTypes.string,
    /** configuration, Configuration object to set different to t */
    configuration: PropTypes.objectOf(PropTypes.any),
};

AuthorTarget.defaultProps = {
    name: '',
    configuration: defaultAttributes.CONFIGURATION,
};
export default AuthorTarget;

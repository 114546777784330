import React from 'react';

import AuthorTooltip from '../AuthorTooltip';
import * as AuthorTooltipConstants from '../AuthorTooltip/Constants';

import DisclosureTooltip from '../DisclosureTooltip';
import * as DisclosureTooltipConstants from '../DisclosureTooltip/Constants';

import EditorialDisclosure from '../EditorialDisclosure';
import * as EditorialDisclosureConstants from '../EditorialDisclosure/Constants';

import UpdatePost from '../../simple/PostDate';
import * as UpdatePostConstants from '../../simple/PostDate/Constants';

// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    BYLINE_MAIN_CONTAINER: 'byline_main_container',
};
export const defaultAttributes = {};
export const sampleAttributes = {
    childrens: [
        <AuthorTooltip
            authorName={AuthorTooltipConstants.sampleAttributes.sampleName}
            avatarImage={AuthorTooltipConstants.sampleAttributes.sampleAvatar}
            socialLinks={AuthorTooltipConstants.sampleAttributes.sampleSocial}
            configuration={
                AuthorTooltipConstants.defaultAttributes.CONFIGURATION
            }
            key="AuthorTooltip"
            bioLink={AuthorTooltipConstants.sampleAttributes.sampleHref}
        >
            {AuthorTooltipConstants.sampleAttributes.sampleText}
        </AuthorTooltip>,
        <DisclosureTooltip
            icon={DisclosureTooltipConstants.defaultAttributes.ICON}
            contentTitle={
                DisclosureTooltipConstants.defaultAttributes.CONTENT_TITLE
            }
            targetTitle={
                DisclosureTooltipConstants.defaultAttributes.TARGET_TITLE
            }
            key="FactCheked"
        >
            {DisclosureTooltipConstants.sampleAttributes.sampleText}
        </DisclosureTooltip>,
        <UpdatePost
            updateDate={UpdatePostConstants.sampleAttributes.DATEUPDATE}
            date={UpdatePostConstants.sampleAttributes.DATE}
            key="UpdatePost"
        />,
        <EditorialDisclosure
            label={EditorialDisclosureConstants.defaultAttributes.LABEL}
            textModal={EditorialDisclosureConstants.sampleAttributes.TEXXTMODAL}
            key="EditorialDisclosure"
        />,
    ],
};

// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var closeTimeout = "1000";
export var ModalPlaceholder = "T_cQ";
export var ModalContent = "T_cR";
export var closeButton = "T_cS";
export var ModalOverlay = "T_cT";
export var visibleOverlay = "T_cV";
export var fadeIn = "T_cW";
export var hiddenOverlay = "T_cX";
export var fadeOut = "T_cY";
// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "f_h";
export var breadcrumbContainer = "f_j";
export var breadcrumb = "f_k";
export var PostComponent = "f_l";
export var titlePostRelated = "f_m";
export var postReadMore = "f_n";
export var articleMain = "f_p";
export var articleSidebarLeft = "f_q";
export var articleSidebarRight = "f_r";
export var articleColumnMain = "f_s";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';
import ModalLightBox from '../../simple/ModalLightBox';
import { main, target } from './EditorialDisclosure.module.scss';

const EditorialDisclosure = ({ label, textModal, modalClass }) => 
{
    const [showModal, setShowModal] = useState(false);
    return (
        <div className={main}>
            <div
                className={`${target}`}
                onClick={() => setShowModal(!showModal)}
                data-test={testAttributes.EDITORIALDISCLOUSURE_MAIN_CONTAINER}
                type="button"
                aria-hidden="true"
            >
                {label}
            </div>

            <ModalLightBox
                data-test={testAttributes.EDITORIALDISCLOUSURE_MODAL}
                isOpen={showModal}
                handleClose={() => setShowModal(false)}
                closeOverLay
                modalClass={modalClass}
            >
                <div>{textModal}</div>
            </ModalLightBox>
        </div>
    );
};
EditorialDisclosure.propTypes = {
    /** children, Author's review ( required ) */
    label:PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array,]),
    textModal: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    modalClass: PropTypes.string,
};

EditorialDisclosure.defaultProps = {
    label: defaultAttributes.LABEL,
    textModal: '',
    modalClass: '',
};
export default EditorialDisclosure;

import React from 'react';
import PropTypes from 'prop-types';

import * as CSS from './ShareButtons.module.scss';
import { KeysToComponentMap, testAttributes } from './Constants';

const ShareButtons = ({ data, url, direction, showLabel }) => 
{
    const isArray = data.constructor === Array;

    const createElement = (item) => 
    {
        if (typeof KeysToComponentMap[item] !== 'undefined') 
        {
            return React.createElement(
                KeysToComponentMap[item].component,
                {
                    key: `social-${item}`,
                    url,
                    'data-test': `${testAttributes.BUTTON_PREFIX}${item}`,
                    ...data[item],
                },
                React.createElement(KeysToComponentMap[item].icon, {
                    round: true,
                    bgStyle: {
                        fill: 'transparent',
                    },
                })
            );
        }

        return null;
    };

    return (
        <div
            className={`${direction === "vertical" ? CSS.shareButtonsContainerVertical : CSS.shareButtonsContainerHorizontal}`}
            data-test={`${direction === "vertical" ? testAttributes.SOCIAL_VERTICAL_CONTAINER : testAttributes.SOCIAL_HORIZONTAL_CONTAINER}` }
        >
            {showLabel && <span data-test={testAttributes.SHARE_LABEL}>Share:</span>}
            {isArray
                ? data.map((item) => createElement(item))
                : Object.keys(data).map((key) => createElement(key))}
        </div>
    );
};

ShareButtons.propTypes = {
    /** The data of the component. Can be either "array" or "object". is required (array | object), check react-share documentation */
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.objectOf(PropTypes.object),
    ]).isRequired,
    /** URL of the page to share (string) */
    url: PropTypes.string,
    /** The direction of the component. Can be either "horizontal" or "vertical". Default is "vertical" (string) */
    direction: PropTypes.string,
    /** showLabel show label "Shared:" (bool) */
    showLabel:  PropTypes.bool,
};

ShareButtons.defaultProps = {
    url: '',
    direction: 'vertical',
    showLabel: false,
};

export default ShareButtons;

const imgSrc =
    'https://secure.gravatar.com/avatar/ca22b1c546c035598fce9871a32ee531?s=96&d=mm&r=g';
// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    AUTHORTOOLTIP_MAIN_CONTAINER: 'authortooltip_main_container',
    AUTHORTOOLTIP_ICON: 'authortooltip_icon',
    AUTHORTARGET_CONTAINER: 'authortarget_container',
    AUTHORTARGET_NAME: 'authortarget_name',
    AUTHORCONTENT_CONTAINER: 'authorcontent_container',
    AUTHORCONTENT_NAME: 'authorcontent_name',
    AUTHORCONTENT_LINK: 'authorcontent_link',
    AUTHORCONTENT_SOCIAL: 'authorcontent_social',
    AUTHORAVATAR_CONTAINER: 'authoravatar_container',
    AUTHORAVATAR_IMAGE: 'authoravatar_image',
};
export const defaultAttributes = {
    CONFIGURATION: {
        prefix: 'By',
    },
    LINKFULLBIO: 'Full bio',
};
export const sampleAttributes = {
    sampleName: 'Oscar Calero',
    sampleText:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    sampleHref: 'https://twitter.com/BillGates',
    sampleSocial: {
        facebook: 'https:www.google.com',
        instagram: 'https:www.google.com',
        linkedin: 'https:www.google.com',
        twitter: 'https:www.google.com',
    },
    sampleSocialOne: {
        facebook: 'https:www.google.com',
    },
    sampleAvatar: {
        layout: 'constrained',
        backgroundColor: '#f8f8f8',
        images: {
            fallback: {
                src: imgSrc,
                srcSet: `${imgSrc} 64w`,
                sizes: '(min-width: 800px) 800px, 100vw',
            },
            sources: [
                {
                    srcSet: `${imgSrc} 64w`,
                    type: 'image/jpeg',
                    sizes: '(min-width: 800px) 800px, 100vw',
                },
            ],
        },
        width: 96,
        height: 100,
    },
};

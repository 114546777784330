import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

import parseHTML from '../../../utilities/parseHTML';
import formattedDate from '../../../utilities/formattedDate';

import {fnParseWith} from '../../../utilities/parses'
import {fnFootnoteParser} from './functionsParse'

import getUrl from '../../../utilities/getUrl';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';

import processHeadArgs from '../../common/process/processHeadArgs';
import { defaultAttributes as AuthorTooltipDefaults } from '../../../components/composite/AuthorTooltip/Constants';
import { defaultAttributes as FactCheckedDefaults } from '../../../components/composite/DisclosureTooltip/Constants';
import { defaultAttributes as EdDisclosureDefaults } from '../../../components/composite/EditorialDisclosure/Constants';
import { shareChannels } from '../Constants';



const processPostArgs = (queried, location, additionalParsers) => 
{
    const { post, pinnedPosts, relatedPosts, relatedGuides } = queried;
    const parsedTitle = parseHTML(post.title);
    const hasUpdatedDate = !!post.cdmfields?.eat?.post_updated_date?.length;
    const parsedDate = hasUpdatedDate
        ? formattedDate(
              DOMPurify.sanitize(post.cdmfields.eat.post_updated_date)
          )
        : formattedDate(DOMPurify.sanitize(post.date));
    const shareUrl =
        typeof location !== 'undefined'
            ? getUrl(location, queried.site.siteMetadata?.siteUrl)
            : getUrl(
                  { pathname: post.uri },
                  queried.site.siteMetadata?.siteUrl
              );
    const postArgs = {};
    postArgs.databaseId = post.databaseId;
    const socialLinks = {};

    const [responseParse,postContent] = fnParseWith(post.content,{
        ...additionalParsers,
        footnoteParser: fnFootnoteParser
    }) ;          
    const listArticles =  responseParse.footnoteParser.return.articleSourceList()

    postArgs.responseParse = responseParse;

    if (
        Array.isArray(post.author?.node?.cdmfields?.authorDisplay?.socialLinks)
    ) 
    {
        post.author.node.cdmfields.authorDisplay.socialLinks.forEach(
            (slink) => 
            {
                socialLinks[slink.channel] = slink.url;
            }
        );
    }
    let authorDescription = '';
    if (post.author?.node?.cdmfields?.metadata) 
    {
        const tempAuthorHeadFields = processHeadArgs(
            post.author.node.cdmfields.metadata,
            { siteUrl: 'https://www.lowermybills.com' }
        );
        try 
        {
            authorDescription = tempAuthorHeadFields.meta
                ? tempAuthorHeadFields.meta.find(
                      (meta) => meta.name === 'description'
                  ).content
                : '';
        }
        catch (er) 
        {
            authorDescription = '';
        }
    }
    authorDescription = parseHTML(authorDescription);
    postArgs.infoPost = {
        title: parsedTitle,
        image: {
            data: post.featuredImage?.node?.localFile?.childImageSharp.data,
            alt: post.featuredImage?.node?.altText || parsedTitle,
        },
        headerInfo: {
            authorInfo: {
                avatarImage:
                    post.author?.node?.localAvatar?.childImageSharp
                        ?.gatsbyImageData,
                configuration: AuthorTooltipDefaults.CONFIGURATION,
                key: 'AuthorTooltip',
                textLink: AuthorTooltipDefaults.LINKFULLBIO,
                socialLinks,
                text: authorDescription,
                authorName: `${post.author?.node?.cdmfields?.authorDisplay?.displayname}`,
                bioLink: post.author?.node?.uri,
                showAuthorTooltip: post.cdmfields?.eat?.show_author_tooltip,
            },
            disclosureInfo: post.cdmfields?.eat?.show_factchecked_tooltip
                ? {
                      icon: FactCheckedDefaults.ICON,
                      contentTitle: FactCheckedDefaults.CONTENT_TITLE,
                      targetTitle: FactCheckedDefaults.TARGET_TITLE,
                      key: 'FactChecked',
                      text: (
                          <>
                              {parseHTML(
                                  queried.FactCheckedDisclosure?.content
                              )}
                          </>
                      ),
                  }
                : null,
            updatePostInfo: {
                updateDate: hasUpdatedDate,
                date: parsedDate,
                key: 'UpdatePost',
            },
            editorialDisclosureInfo: {
                label: EdDisclosureDefaults.LABEL,
                key: 'EditorialDisclosure',
                textModal: <>{parseHTML(queried.EdDisclosure?.content)}</>,
            },
        },        
        content: postContent, 
        
    };
    postArgs.breadcrumbs = post.cdmfields.breadcrumb.map((crumb) => ({
        id: crumb.id,
        text: parseHTML(crumb.text),
        url: DOMPurify.sanitize(crumb.url),
    }));
    postArgs.sharebutton = shareUrl
        ? {
              data: shareChannels,
              url: shareUrl,
          }
        : null;
    postArgs.articleSourceList = listArticles;
    postArgs.postsRelated = filterAndSortRelatedPosts(
        post.databaseId,
        [...relatedPosts.edges, ...relatedGuides.edges],
        pinnedPosts.edges,
    );
    return postArgs;
};

const processQueried = (queried, location, additionalParsers={}) => 
{
    const allArgs = {
        postArgs: processPostArgs(queried, location, additionalParsers),
        headArgs: processHeadArgs(
            queried.post.cdmfields.metadata,
            queried.site.siteMetadata
        ),
    };
    return allArgs;
};

export default processQueried;

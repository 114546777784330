import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../simple/Icon';
import { testAttributes, defaultAttributes } from '../Constants';
import { target, targetIcon } from './DisclosureTarget.module.scss';

const DisclosureTarget = ({ title, icon }) => (
    <div
        className={`${target}`}
        data-test={testAttributes.DISCLOSURETARGET_CONTAINER}
    >
        <span>{title}</span>
        <Icon
            icon={icon}
            className={targetIcon}
            dataTest={testAttributes.DISCLOSURETOOLTIP_ICON}
        />
    </div>
);
DisclosureTarget.propTypes = {
    /** title, Title of content of the tooltip */
    title: PropTypes.string,
    /** icon, Icon on the side of title of the tooltip */
    icon: PropTypes.string,
};

DisclosureTarget.defaultProps = {
    title: defaultAttributes.TITLE,
    icon: '',
};
export default DisclosureTarget;

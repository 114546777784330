import React from 'react';

export const testAttributes = {
    ARTICLE_SOURCE_MAIN_CONTAINER: 'article_source_container',
    ARTICLE_SOURCE_HEADER: 'article_source_header',
    ARTICLE_SOURCE_PREFIX: 'article_source_item_',
    ARTICLE_SOURCE_LIST_CONTAINER: 'article_source_list_container',
};

export const dataSample = {
    sourceList: [
        '<p>Reference title. <a href="http://www.google.com">www.referencedomain.com/referenceURIAny</a> other required details<p>',
        <p>
            Eu cillum mentitum adipisicing in do duis te sunt, doctrina
            praetermissum sed admodum, offendit minim labore admodum esse. Irure
            laboris coniunctione, elit ingeniis an quae tempor, do duis
            exquisitaque, admodum nisi multos hic quis id se laboris firmissimum
            quo eiusmod quid noster admodum tamen, quo ea dolore magna quorum a
            ut elit aliquip firmissimum
            <span className="cdm-footnote-container">
                Article Source:
                <a
                    href="https://yahoo.com"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                >
                    https://yahoo.com
                </a>
                - credit here
            </span>
            .
        </p>,
        '<p>Eu cillum mentitum adipisicing in do duis te sunt, doctrina praetermissum sed admodum, offendit minim labore admodum esse. Irure laboris coniunctione, elit ingeniis an quae tempor, do duis exquisitaque, admodum nisi multos hic quis id se laboris firmissimum quo eiusmod quid noster admodum tamen, quo ea dolore magna quorum a ut elit aliquip firmissimum <span class="cdm-footnote-container">Article Source: <a href="https://yahoo.com" target="_blank" rel="noreferrer noopener nofollow">https://yahoo.com</a> - credit here</span>. </p>',
    ],
};

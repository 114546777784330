import React from 'react';
import PropTypes from 'prop-types';
import Linker from '../../../simple/Linker';
import SocialLinks from '../../../simple/SocialLinks';
import AuthorAvatar from '../AuthorAvatar';
import { testAttributes } from '../Constants';
import {
    content,
    authorDetails,
    authorName,
    fullBioLink,
    socialIcons,
} from './AuthorContent.module.scss';

const AuthorContent = ({
    name,
    children,
    socialLinks,
    avatarImage,
    textLink,
    bioLink,
}) => (
    <div className={content} data-test={testAttributes.AUTHORCONTENT_CONTAINER}>
        <AuthorAvatar avatarImage={avatarImage} />
        <div className={authorDetails}>
            <h5
                className={authorName}
                data-test={testAttributes.AUTHORCONTENT_NAME}
            >{`${name}`}</h5>

            <p>{children}</p>
            <Linker
                href={bioLink}
                className={fullBioLink}
                data-test={testAttributes.AUTHORCONTENT_LINK}
            >
                {textLink}
            </Linker>
            <SocialLinks
                links={socialLinks}
                addClass={socialIcons}
                data-test={testAttributes.AUTHORCONTENT_SOCIAL}
            />
        </div>
    </div>
);
AuthorContent.propTypes = {
    /** name, Name of author */
    name: PropTypes.string,
    /** Children, Component trigger the AuthorContent */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
    ]),
    /** socialLinks, SocialLinks of the author */
    socialLinks: PropTypes.objectOf(PropTypes.any),
    /** avatarImage, Imagen of Avatar of the author */
    avatarImage: PropTypes.objectOf(PropTypes.any),
    /** textLink, Text to link full bio */
    textLink: PropTypes.string,
    /** Author's biolink ( required ) */
    bioLink: PropTypes.string.isRequired,
};

AuthorContent.defaultProps = {
    name: '',
    children: '',
    socialLinks: [],
    avatarImage: undefined,
    textLink: '',
};
export default AuthorContent;

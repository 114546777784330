import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import DisclosureTarget from '../../composite/DisclosureTooltip/DisclosureTarget';
import parseHTML from '../../../utilities/parseHTML';
import { testAttributes } from './Constants';
import {
    articleSourceWrapper,
    articleSourceContainer,
    articlesourceHeader,
    active,
} from './ArticleSource.module.scss';

const listIcons = {
    'plus-icon': 'cdm:plus-icon',
    'minus-icon': 'cdm:minus-icon',
};

const ArticleSource = ({ id, title, sourceList }) => 
{
    const [isOpen, setIsOpen] = useState(false);
    const listContent = useRef();

    const getWrapperStyle = () =>
        isOpen && listContent
            ? {
                  height: listContent.current?.scrollHeight + 30,
              }
            : { height: 0 };

    const fnValidateContent = (content) =>
        typeof content === 'string' ? parseHTML(content) : content;

    return (
        <div
            className={articleSourceContainer}
            data-test={testAttributes.ARTICLE_SOURCE_MAIN_CONTAINER}
        >
            <div
                id={id}
                className={articlesourceHeader}
                onClick={() => setIsOpen(!isOpen)}
                role="presentation"
                data-test={testAttributes.ARTICLE_SOURCE_HEADER}
            >
                <DisclosureTarget
                    title={title}
                    icon={listIcons[!isOpen ? 'plus-icon' : 'minus-icon']}
                />
            </div>
            <div
                aria-expanded={isOpen ? 'true' : 'false'}
                
                className={`${articleSourceWrapper} ${isOpen ? active : ''}`}
                style={getWrapperStyle()}
                data-test={testAttributes.ARTICLE_SOURCE_LIST_CONTAINER}
            >
                {sourceList.length > 0 && (
                    <ol ref={listContent}>
                        {sourceList.map((content, index) => (
                            <li
                                data-test={`${testAttributes.ARTICLE_SOURCE_PREFIX}${index}`}
                                key={String(index)}
                            >
                                {fnValidateContent(content)}
                            </li>
                        ))}
                    </ol>
                )}
            </div>
        </div>
    );
};

ArticleSource.propTypes = {
    /** Id prop, allow link to this page element */
    id: PropTypes.string,
    /** title prop, article source title */
    title: PropTypes.string,
    /** sourceList prop; array with React components or HTML strings */
    sourceList: PropTypes.arrayOf(PropTypes.any),
};

ArticleSource.defaultProps = {
    id: 'article-sources-container',
    title: 'ARTICLE SOURCES',
    sourceList: [],
};

export default ArticleSource;

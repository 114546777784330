import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Icon } from '@iconify/react';
import { testAttributes } from './Constants';
import {
    ModalOverlay,
    ModalContent,
    ModalPlaceholder,
    visibleOverlay,
    closeButton,
    hiddenOverlay,
    closeTimeout,
} from './ModalLightBox.module.scss';

export const fnValidateRoot = () => 
{
    let idroot = '___gatsby';
    if (
        typeof document !== 'undefined' &&
        document.getElementById(idroot) == null
    )
        idroot = 'root';
    ReactModal.setAppElement(`#${idroot}`);
};

const ModalLightBox = ({
    isOpen,
    handleClose,
    children,
    dataTest,
    closeOverLay,
    modalClass,
}) => 
{
    fnValidateRoot();

    return (
        <div data-test={testAttributes.MODALLIGHTBOX_MAIN_CONTAINER}>
            <ReactModal
                closeTimeoutMS={Math.max(50, closeTimeout - 100)}
                isOpen={isOpen}
                onRequestClose={handleClose}
                className={`${ModalPlaceholder} ${modalClass}`}
                shouldCloseOnOverlayClick={closeOverLay}
                onAfterOpen={() =>   
                {
                    document.body.style.overflow = 'hidden';
                }}
                onAfterClose={() => 
                {
                    document.body.style.overflow = 'unset';
                }}
                overlayClassName={`${ModalOverlay} ${
                    isOpen ? visibleOverlay : hiddenOverlay
                }`}
            >
                <div className={ModalContent} data-test={dataTest}>
                    {children}
                    <button
                        type="button"
                        className={closeButton}
                        onClick={handleClose}
                        aria-label="close"
                        data-test={testAttributes.MODALLIGHTBOX_BUTTON}
                    >
                        <Icon
                            icon="carbon:close-outline"
                            data-test={testAttributes.MODALLIGHTBOX_ICON}
                        />
                    </button>
                </div>
            </ReactModal>
        </div>
    );
};

ModalLightBox.propTypes = {
    /** Attribute of Modal; Define when the modal is show (true) or hide (false) */
    isOpen: PropTypes.bool.isRequired,
    /** Event of Modal ; Fire after the modal is closed */
    handleClose: PropTypes.func.isRequired,
    /** Childens of Modal; All children can show inside the Modal */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** Data-test of Content inside of Modal; Data-test to test content inside of Modal */
    dataTest: PropTypes.string,
    /** Allow Close over lay Click; */
    closeOverLay: PropTypes.bool,
    /** Custom Modal Class; */
    modalClass: PropTypes.string,
};

ModalLightBox.defaultProps = {
    // isOpen: false,
    dataTest: testAttributes.MODALLIGHTBOX_CONTENT,
    // closeOverLay: true,
    children: '',
    closeOverLay: false,
    modalClass: '',
};

export default ModalLightBox;

// eslint-disable-next-line import/prefer-default-export
import parseHTML from "../../../utilities/parseHTML"

export const testAttributes = {
    EDITORIALDISCLOUSURE_MAIN_CONTAINER: 'editorialdisclosure_main_container',
    EDITORIALDISCLOUSURE_MODAL: 'editorialdisclosure_modal',
};

const sampleHTML = parseHTML(`
<b><i>Editorial disclosure 📰</i></b>
`)
export const defaultAttributes = {
    LABEL: 'Editorial Disclosure',
    LABELHTML:sampleHTML
};
export const sampleAttributes = {
    ModalClassName: 'ModalEditorialDisclosure',
    TEXXTMODAL:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et rutrum dolor. Sed elementum tellus quis pulvinar iaculis. Suspendisse elementum, massa ut placerat porttitor, dui dolor malesuada felis, dignissim lacinia elit nibh ac risus. Integer bibendum pretium tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur eget lacus tortor. Suspendisse sagittis elit leo, ut ullamcorper enim facilisis et. Curabitur aliquet neque id neque congue dignissim. Sed facilisis augue sed tellus malesuada mattis. Donec semper justo sed congue viverra. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Ut imperdiet purus tempus tellus commodo, quis varius libero lacinia. Donec porttitor tellus sapien, vel consequat orci cursus id. Donec tempus sagittis metus ut vulputate. Curabitur sollicitudin nec purus et elementum.',
};

// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    DISCLOSURETOOLTIP_MAIN_CONTAINER: 'disclosuretooltip_main_container',
    DISCLOSURETOOLTIP_ICON: 'disclosuretooltip_icon',
    DISCLOSURETARGET_CONTAINER: 'disclosuretarget_container',
    DISCLOSURECONTENT_CONTAINER: 'disclosurecontent_container',
};
export const defaultAttributes = {
    CONTENT_TITLE: 'Fact-checked',
    TARGET_TITLE: 'FACT-CHECKED',
    ICON: 'yes-icon',
};
export const sampleAttributes = {
    sampleText:
        'At PolicyPilot, we value your trust. Our content is always original and rigorously fact-checked by an experienced editor using recent, reputable, and unbiased sources. We’re devoted to quality — and that means no cutting corners.',
};

import React from 'react';
import PropTypes from 'prop-types';
import { defaultAttributes, testAttributes } from './Constants';
import AuthorTarget from './AuthorTarget';
import AuthorContent from './AuthorContent';
import Tooltip from '../Tooltip';

const AuthorTooltip = ({
    authorName,
    children,
    socialLinks,
    avatarImage,
    configuration,
    bioLink,
    textLink,
    showAuthorTooltip,
    hasCloseButton
}) => (
    <Tooltip
        data-test={testAttributes.AUTHORTOOLTIP_MAIN_CONTAINER}
        placement="bottom_start"
        theme="WithArrow"
        showTooltip={showAuthorTooltip}
        hasCloseButton={hasCloseButton}
        content={
            <AuthorContent
                name={authorName}
                socialLinks={socialLinks}
                avatarImage={avatarImage}
                textLink={textLink}
                bioLink={bioLink}
            >
                {children}
            </AuthorContent>
        }
        trigger="mouseenter"
    >
        <AuthorTarget name={authorName} configuration={configuration} />
    </Tooltip>
);
AuthorTooltip.propTypes = {
    /** name, Author's name ( required ) */
    authorName: PropTypes.string.isRequired,
    /** children, Author's review ( required ) */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
    ]),
    /** SocialLinks, Array from all social pages of the author */
    socialLinks: PropTypes.objectOf(PropTypes.any),
    /** avatarImage(gatsbyImage), Author's image avatar */
    avatarImage: PropTypes.objectOf(PropTypes.any),
    /** Author's biolink ( required ) */
    bioLink: PropTypes.string,
    /** text of link to full bio page */
    textLink: PropTypes.string,
    /** configuration, Configuration to the component prefix */
    configuration: PropTypes.objectOf(PropTypes.any),
    /** showAuthorTooltip, shows tool tip bellow autor name */
    showAuthorTooltip: PropTypes.bool,
    /** hasCloseButton: whether a clickable close button [X] is rendered */
    hasCloseButton: PropTypes.bool
};

AuthorTooltip.defaultProps = {
    children: '',
    socialLinks: [],
    avatarImage: undefined,
    configuration: defaultAttributes.CONFIGURATION,
    bioLink: '#',
    textLink: defaultAttributes.LINKFULLBIO,
    showAuthorTooltip: true,
    hasCloseButton: false,
};
export default AuthorTooltip;

import React from 'react';
import PropTypes from 'prop-types';
import { addCollection, Icon } from '@iconify/react';

import { testAttributes } from './Constants';
import customIcons from './svgIconsList';

addCollection(customIcons);

const CustomArticle = ({ icon, className, dataTest, width, height }) => (
    <Icon
        icon={icon}
        className={className}
        data-test={dataTest}
        width={width}
        height={height}
    />
);

CustomArticle.propTypes = {
    /** icon, Icon name from iconify gallery */
    icon: PropTypes.string,
    /** classname, Custom class name */
    className: PropTypes.string,
    /** dataTest, data-test for testing */
    dataTest: PropTypes.string,
    /** width, custom size */
    width: PropTypes.number,
    /** height, custom size */
    height: PropTypes.number,
};

CustomArticle.defaultProps = {
    icon: '',
    className: '',
    dataTest: testAttributes.ICON_ELEMENT,
    width: 16,
    height: 16,
};

export default CustomArticle;

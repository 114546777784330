import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Byline from '../../../composite/Byline';
import { testAttributes } from '../Constants';

import AuthorTooltip from '../../../composite/AuthorTooltip';
import DisclosureTooltip from '../../../composite/DisclosureTooltip';
import EditorialDisclosure from '../../../composite/EditorialDisclosure';
import UpdatePost from '../../../simple/PostDate';
import {
    headerTextPortion,
    headerTitle,
    headerByline,
    headerImage,
} from './PostPageHeader.module.scss';
/**
 Component that allows displaying the hero section  using 3 subcomponent ( HetoTitle,HeroCard,HeroSubcategory  ).
 */
const PostPageHeader = ({ title, image, introContent, headerInfo }) => (
    <header className="article-info">
        <div className={headerTextPortion}>
            <h1
                itemProp="headline"
                data-test={testAttributes.POSTPAGEHEADER_TITLE}
                className={headerTitle}
            >
                {title}
            </h1>
            <div className="editorialBody">
                { introContent }
            </div>
            <Byline
                data-test={testAttributes.POSTPAGEHEADER_BYLINE}
                className={headerByline}
            >
                <AuthorTooltip
                    authorName={headerInfo.authorInfo.authorName}
                    avatarImage={headerInfo.authorInfo.avatarImage}
                    bioLink={headerInfo.authorInfo.bioLink}
                    socialLinks={headerInfo.authorInfo.socialLinks}
                    configuration={headerInfo.authorInfo.configuration}
                    key={headerInfo.authorInfo.key}
                    data-test={testAttributes.POSTPAGEHEADER_AUTHORTOOLTIP}
                    showAuthorTooltip={headerInfo.authorInfo.showAuthorTooltip}
                >
                    {headerInfo.authorInfo.text}
                </AuthorTooltip>
                {headerInfo.disclosureInfo && (
                    <DisclosureTooltip
                        icon={headerInfo.disclosureInfo.icon}
                        contentTitle={headerInfo.disclosureInfo.contentTitle}
                        targetTitle={headerInfo.disclosureInfo.targetTitle}
                        key={headerInfo.disclosureInfo.key}
                        data-test={testAttributes.POSTPAGEHEADER_DISCLOSURETOOLTIP}
                    >
                        {headerInfo.disclosureInfo.text}
                    </DisclosureTooltip>
                )}
                <UpdatePost
                    updateDate={headerInfo.updatePostInfo.updateDate}
                    date={headerInfo.updatePostInfo.date}
                    key={headerInfo.updatePostInfo.key}
                    data-test={testAttributes.POSTPAGEHEADER_UPDATEPOST}
                />
                <EditorialDisclosure
                    label={headerInfo.editorialDisclosureInfo.label}
                    textModal={headerInfo.editorialDisclosureInfo.textModal}
                    key={headerInfo.editorialDisclosureInfo.kkey}
                    data-test={testAttributes.POSTPAGEHEADER_EDITORIALDISCLOSURE}
                />
            </Byline>
        </div>
        {image && (
            <GatsbyImage
                image={image.data}
                alt={image.alt}
                data-test={testAttributes.POSTPAGEHEADER_IMAGE}
                className={headerImage}
                itemProp="image"
            />
        )}
    </header>
);
PostPageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.objectOf(PropTypes.any).isRequired,
    introContent: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.object,
        PropTypes.any,
    ]),
    headerInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

PostPageHeader.defaultProps = {
    introContent: null
};
export default PostPageHeader;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@iconify/react';
import { testAttributes, defaultAttributes } from '../Constants';
import {
    content,
    titleContent,
    titleContentIcon,
    titleContentLabel,
} from './DisclosureContent.module.scss';

const DisclosureContent = ({ title, icon, children }) => (
    <div
        className={content}
        data-test={testAttributes.DISCLOSURECONTENT_CONTAINER}
    >
        <div className={titleContent}>
            <Icon icon={icon} className={titleContentIcon} />
            <h5 className={titleContentLabel}>{title}</h5>
        </div>
        <div>{children}</div>
    </div>
);
DisclosureContent.propTypes = {
    /** title, Title of content of the tooltip */
    title: PropTypes.string,
    /** icon, Icon on the side of title of the tooltip */
    icon: PropTypes.string,
    /** Children, Component trigger the DisclosureContent */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

DisclosureContent.defaultProps = {
    title: defaultAttributes.TITLE,
    children: '',
    icon: '',
};
export default DisclosureContent;

const formattedDate = (rawDateString, tabular = false, loc = 'en-US') => 
{
    let dateString = rawDateString.trim();
    if (dateString.indexOf('T') < 0) 
    {
        dateString += 'T12:24:48';
    }
    const d = new Date(Date.parse(dateString));
    if (loc !== 'en-US') return d.toLocaleDateString(loc);
    const year = d.toLocaleDateString(loc, { year: 'numeric' });
    const day = d.toLocaleDateString(loc, { day: 'numeric' });
    let month = d.toLocaleDateString(loc, { month: 'long' });
    if (tabular) 
    {
        month = month.substring(0, 3);
    }
    else 
    if (month === 'September') 
    {
        month = 'Sept.';
    }
    else if (month.length > 5) 
    {
        month = `${month.substring(0, 3)}.`;
    }
    return `${month} ${day}, ${year}`;
};
export default formattedDate;

/* eslint-disable import/prefer-default-export */
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TumblrShareButton,
    TumblrIcon,
} from 'react-share';

export const KeysToComponentMap = {
    Facebook: { component: FacebookShareButton, icon: FacebookIcon },
    Twitter: { component: TwitterShareButton, icon: TwitterIcon },
    Whatsapp: { component: WhatsappShareButton, icon: WhatsappIcon },
    Linkedin: { component: LinkedinShareButton, icon: LinkedinIcon },
    email: { component: EmailShareButton, icon: EmailIcon },
    Pinterest: { component: PinterestShareButton, icon: PinterestIcon },
    Reddit: { component: RedditShareButton, icon: RedditIcon },
    Tumblr: { component: TumblrShareButton, icon: TumblrIcon },
};

export const testAttributes = {
    SOCIAL_HORIZONTAL_CONTAINER: 'social_button_horizontal_container',
    SOCIAL_VERTICAL_CONTAINER: 'social_button_vertical_container',
    BUTTON_PREFIX: 'social_button_',
    SHARE_LABEL: 'share_label'
};

export const dataSample = {
    dataObject: {
        Facebook: { quote: '', hashtag: '' },
        Twitter: { title: '', via: '', hashtags: [], related: [] },
        Whatsapp: { title: '', separator: '' },
        Linkedin: { title: '', summary: '', source: '' },
        Pinterest: {
            media: 'http://www.example.com/image.png',
            description: '',
        },
        Reddit: { title: '' },
        Tumblr: { title: '', tags: [], caption: '', posttype: '' },
        email: { subject: '', body: '', separator: '' },
    },
    data: [
        'Facebook',
        'Twitter',
        'Whatsapp',
        'Linkedin',
        'Reddit',
        'Tumblr',
        'email',
    ],
    url: 'https://www.google.com',
};

import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';

import { main, target } from './PostDate.module.scss';

export const fngetPrefix = (updateDate) =>
    updateDate ? 'Updated ' : 'Published ';

const PostDate = ({ updateDate, date }) => (
    <div className={main}>
        <div
            className={`${target}`}
            data-test={testAttributes.POSTDATE_MAIN_CONTAINER}
        >
            {fngetPrefix(updateDate)}
            {date}
        </div>
    </div>
);
PostDate.propTypes = {
    /** children, Author's review ( required ) */
    updateDate: PropTypes.bool,
    date: PropTypes.string,
};

PostDate.defaultProps = {
    updateDate: false,
    date: '',
};
export default PostDate;

import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import { byline, bylineItem, itemChild } from './Byline.module.scss';

const getKey = (index) => `byline_item_${index}`;

const fnDecoratorChild = (child) =>
    React.cloneElement(child, { className: itemChild });

const Byline = ({ children }) => (
    <div className={byline} data-test={testAttributes.BYLINE_MAIN_CONTAINER}>
        {children &&
            children.map((item, index) =>
                item ? (
                    <div className={bylineItem} key={getKey(index)}>
                        {fnDecoratorChild(item)}
                    </div>
                ) : null
            )}
    </div>
);
Byline.propTypes = {
    /** children, Author's review ( required ) */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

Byline.defaultProps = {
    children: '',
};
export default Byline;

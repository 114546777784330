import * as AuthorTooltipConstants from '../../composite/AuthorTooltip/Constants';
import * as DisclosureTooltipConstants from '../../composite/DisclosureTooltip/Constants';
import * as UpdatePostConstants from '../../simple/PostDate/Constants';
import * as EditorialDisclosureConstants from '../../composite/EditorialDisclosure/Constants';
import * as ArticleSourceConstants from '../../simple/ArticleSource/Constants';
import * as GridContainerConstants from '../../composite/GridContainer/Constants';

import * as HeaderConstants from '../../composite/Header/Constants';
import * as FooterConstants from '../../composite/Footer/Constants';

import sampleHTML from './stories/EditorialSample/sampleHTML';
import sampleEmbedHTML from './stories/EditorialSample/sampleEmbed';

export const testAttributes = {
    POSTPAGE_MAIN: 'postpage_main',
    POSTPAGE_BREADCRUMB: 'postpage_breadcrumb',
    POSTPAGE_ARTICLEMAIN: 'postpage_articlemain',
    POSTPAGE_SHAREBUTTON: 'postpage_sharebutton',
    POSTPAGE_ARTICLE: 'postpage_article',
    POSTPAGE_CONTENT: 'postpage_content',
    POSTPAGE_GRIDCONTAINER: 'postpage_gridcontainer',

    POSTPAGEHEADER_TITLE: 'postpageheader_title',
    POSTPAGEHEADER_IMAGE: 'postpageheader_image',
    POSTPAGEHEADER_BYLINE: 'postpageheader_byline',
    POSTPAGEHEADER_AUTHORTOOLTIP: 'postpageheader_authortooltip',
    POSTPAGEHEADER_DISCLOSURETOOLTIP: 'postpageheader_disclosuretooltip',
    POSTPAGEHEADER_UPDATEPOST: 'postpageheader_updatepost',
    POSTPAGEHEADER_EDITORIALDISCLOSURE: 'postpageheader_editorialdisclosure',
    POSTPAGE_TITLREADMORE: 'postpage_titlereadmore',
};
const imgSrc = 'https://loremflickr.com/1200/675/dog';
const sampleImage = {
    data: {
        layout: 'constrained',
        backgroundColor: '#f8f8f8',
        images: {
            fallback: {
                src: imgSrc,
                srcSet: `${imgSrc} 64w`,
                sizes: '(min-width: 800px) 800px, 100vw',
            },
            sources: [
                {
                    srcSet: `${imgSrc} 64w`,
                    type: 'image/jpeg',
                    sizes: '(min-width: 800px) 800px, 100vw',
                },
            ],
        },
        width: 1200,
        height: 600,
    },
    alt: 'alt 1',
};

export const sampleAttributes = {
    databaseId: 1111,
    infoPost: {
        title: 'Don’t Miss Out On These Hidden Auto Insurance Discounts',
        image: sampleImage,
        headerInfo: {
            authorInfo: {
                authorName: AuthorTooltipConstants.sampleAttributes.sampleName,
                avatarImage:
                    AuthorTooltipConstants.sampleAttributes.sampleAvatar,
                socialLinks:
                    AuthorTooltipConstants.sampleAttributes.sampleSocial,
                configuration:
                    AuthorTooltipConstants.defaultAttributes.CONFIGURATION,
                key: 'AuthorTooltip',
                text: AuthorTooltipConstants.sampleAttributes.sampleText,
                bioLink: AuthorTooltipConstants.sampleAttributes.sampleHref,
            },
            disclosureInfo: {
                icon: DisclosureTooltipConstants.defaultAttributes.ICON,
                contentTitle:
                    DisclosureTooltipConstants.defaultAttributes.CONTENT_TITLE,
                targetTitle:
                    DisclosureTooltipConstants.defaultAttributes.TARGET_TITLE,
                key: 'FactCheked',
                text: DisclosureTooltipConstants.sampleAttributes.sampleText,
            },
            updatePostInfo: {
                updateDate: UpdatePostConstants.sampleAttributes.DATEUPDATE,
                date: UpdatePostConstants.sampleAttributes.DATE,
                key: 'UpdatePost',
            },
            editorialDisclosureInfo: {
                label: EditorialDisclosureConstants.defaultAttributes.LABEL,
                textModal:
                    EditorialDisclosureConstants.sampleAttributes.TEXXTMODAL,
                key: 'EditorialDisclosure',
            },
        },
        content: sampleHTML,
        contentEmbed: sampleEmbedHTML,
    },
    breadcrumbs: [
        {
            id: 10802,
            term_id: null,
            text: 'Home',
            url: 'https://dev.lowermybills.com/',
        },
        {
            id: 11802,
            term_id: null,
            text: 'Personal Loans',
            url: 'https://dev.lowermybills.com/personal-loans/',
        },

        {
            id: 14984,
            term_id: null,
            text: 'How To Get A Loan With Bad Credit',
            url:
                'https://dev-lmb.pantheonsite.io/personal-loans/how-to-get-a-loan-with-bad-credit/',
        },
    ],
    sharebutton: {
        data: [
            'Facebook',
            'Twitter',
            // 'Whatsapp',
            'Linkedin',
            // 'Reddit',
            // 'Tumblr',
            // 'email',
        ],
        url: 'https://www.google.com',
    },
    articleSourceList: ArticleSourceConstants.dataSample.sourceList,
    postsRelated: GridContainerConstants.gridParams.samplePosts,
    headerProps: {
        headerMenu: HeaderConstants.sampleAttributes.headerMenu,
        logoUrl: HeaderConstants.defaultAttributes.url,
        logoAlt: HeaderConstants.defaultAttributes.alt,
    },
    footerProps: {
        itemsMenu: FooterConstants.sampleAttributes.listMenu,
        socialLinks: FooterConstants.sampleAttributes.links,
    },
};

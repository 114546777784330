import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes, defaultAttributes } from './Constants';
import DisclosureTarget from './DisclosureTarget';
import DisclosureContent from './DisclosureContent';
import Tooltip from '../Tooltip';

import { disclosureMain } from './DisclosureTooltip.module.scss';

export const listIcons = {
    'yes-icon': 'dashicons:yes-alt',
};

const DisclosureTooltip = ({ contentTitle, targetTitle, icon, children, hasCloseButton }) => (
    <div
        data-test={testAttributes.DISCLOSURETOOLTIP_MAIN_CONTAINER}
        className={disclosureMain}
    >
        <Tooltip
            placement="bottom"
            theme="WithArrow"
            content={
                <DisclosureContent title={contentTitle} icon={listIcons[icon]}>
                    {children}
                </DisclosureContent>
            }
            trigger="mouseenter"
            hasCloseButton={hasCloseButton}
        >
            <DisclosureTarget title={targetTitle} icon={listIcons[icon]} />
        </Tooltip>
    </div>
);
DisclosureTooltip.propTypes = {
    /** title, Title of content of the tooltip */
    contentTitle: PropTypes.string,
    /** title, Title of content of the tooltip */
    targetTitle: PropTypes.string,
    /** icon, Icon on the side of title of the tooltip */
    icon: PropTypes.oneOf(Object.keys(listIcons)),
    /** Children, Component trigger the DisclosureTooltip */
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    /** hasCloseButton: whether a clickable close button [X] is rendered */
    hasCloseButton: PropTypes.bool
};

DisclosureTooltip.defaultProps = {
    contentTitle: defaultAttributes.CONTENT_TITLE,
    targetTitle: defaultAttributes.TARGET_TITLE,
    icon: defaultAttributes.ICON,
    children: '',
    hasCloseButton: false
};
export default DisclosureTooltip;

const iconList = {
    prefix: 'cdm',
    icons: {
        'plus-icon': {
            body:
                '<path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58173 3.58172 0 8 0C12.4183 0 16 3.58173 16 8ZM6.83451 2.30093H9.16553C9.44085 2.30093 9.6611 2.52118 9.6611 2.79649V5.84335C9.6611 6.11865 9.88136 6.33891 10.1567 6.33891H13.2035C13.4788 6.33891 13.6991 6.55917 13.6991 6.83449V9.16551C13.6991 9.44083 13.4788 9.66109 13.2035 9.66109H10.1567C9.88136 9.66109 9.6611 9.88133 9.6611 10.1566V13.2035C9.6611 13.4788 9.44085 13.6991 9.16553 13.6991H6.83451C6.5592 13.6991 6.33894 13.4788 6.33894 13.2035V10.1566C6.33894 9.88133 6.11869 9.66109 5.84337 9.66109H2.79652C2.52121 9.66109 2.30096 9.44083 2.30096 9.16551V6.83449C2.30096 6.55917 2.52121 6.33891 2.79652 6.33891H5.84337C6.11869 6.33891 6.33894 6.11865 6.33894 5.84335V2.79651C6.33894 2.52118 6.5592 2.30093 6.83451 2.30093Z" fill="currentcolor" />',
        },
        'minus-icon': {
            body:
                '<path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#008A00"/><path d="M2.86757 6.40999L5.91441 6.40999H10.2277L13.2746 6.40999C13.5499 6.40999 13.7701 6.63024 13.7701 6.90556V9.23658C13.7701 9.5119 13.5499 9.73215 13.2746 9.73215L10.2277 9.73215H5.91441L2.86757 9.73215C2.59225 9.73215 2.372 9.5119 2.372 9.23658V6.90556C2.372 6.63024 2.59225 6.40999 2.86757 6.40999Z" fill="white"/>',
        },
    },
};

export default iconList;

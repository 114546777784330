import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../simple/Breadcrumb';
import PostPageHeader from './PostPageHeader';
import ArticleSource from '../../simple/ArticleSource';
import GridContainer from '../../imported/GridContainer';
import ShareButtons from '../../simple/ShareButtons';
import { testAttributes } from './Constants';
import { build } from '../../../Constants.json';

import { articleSourcesId } from '../../composite/Footnote/Constants';
import {
    articleColumnMain,
    PostComponent,
    articleMain,
    content,
    articleSidebarLeft,
    breadcrumbContainer,
    titlePostRelated,
} from './PostPage.module.scss';

/**
 Component that allows generate statci page for posts pages.
 */
export const fngetMorePosts = (posts, index, newIndex) =>
    posts.slice(index, newIndex);
const PostPage = ({ postInfo, gridTitle }) => (
    <div
        className={`${PostComponent} site-content ${content} `}
        id="primary"
        data-test={testAttributes.POSTPAGE_MAIN}
    >
        <div className={breadcrumbContainer}>
            <div className={`${articleSidebarLeft}`} />
            <Breadcrumb
                breadcrumbs={postInfo.breadcrumbs}
                data-test={testAttributes.POSTPAGE_BREADCRUMB}
                className="breadcrumb"
            />
        </div>

        <div
            className={`article-${postInfo.databaseId} ${articleMain}  `}
            role="main"
            data-test={testAttributes.POSTPAGE_ARTICLEMAIN}
        >
            <div className={`${articleSidebarLeft}`}>
                <ShareButtons
                    data={postInfo.sharebutton.data}
                    url={postInfo.sharebutton.url}
                    data-test={testAttributes.POSTPAGE_SHAREBUTTON}
                />
            </div>

            <article
                className={`${articleColumnMain} `}
                itemScope
                itemType="https://schema.org/Article"
                data-test={testAttributes.POSTPAGE_ARTICLE}
            >
                <PostPageHeader
                    title={postInfo.infoPost.title}
                    image={postInfo.infoPost.image}
                    headerInfo={postInfo.infoPost.headerInfo}
                />
                <div
                    className="editorialBody"
                    itemProp="articleBody"
                    data-test={testAttributes.POSTPAGE_CONTENT}
                >
                    {postInfo.infoPost.content}
                    {postInfo.articleSourceList?.length ? (
                        <ArticleSource
                            id={articleSourcesId}
                            sourceList={postInfo.articleSourceList}
                        />
                    ) : null}
                </div>
            </article>
        </div>
        <h2
            data-test={testAttributes.POSTPAGE_TITLREADMORE}
            className={titlePostRelated}
        >
            {gridTitle}
        </h2>
        <GridContainer
            queryArgs={{
                posts: postInfo.postsRelated,
                total_found: postInfo.postsRelated.length,
            }}
            type="button"
            gridLimit={build.defaults.POSTS_PER_PAGE}
            getMorePosts={fngetMorePosts}
            btnLabel="SEE MORE"
            data-test={testAttributes.POSTPAGE_GRIDCONTAINER}
            containerId={build.defaults.GRID_CONTAINER_ID}
        />
    </div>
);
PostPage.propTypes = {
    /** postInfo, data for GraphQL  */
    postInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    /** title, for grid container default Read More  */
    gridTitle: PropTypes.string,
};

PostPage.defaultProps = {
    gridTitle: 'Read More',
};
export default PostPage;

const getUrl = (location, siteURL) => 
{
    if (location?.href) 
    {
        return location.href;
    }
    try 
    {
        const urlOb = new URL(location.pathname, location.origin || siteURL);
        return urlOb?.href;
    }
    catch (err) 
    {
        return false;
    }
};

export default getUrl;

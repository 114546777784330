import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { testAttributes } from '../Constants';
import { authorImage } from './AuthorAvatar.module.scss';

const AuthorAvatar = ({ avatarImage }) => (
    <div data-test={testAttributes.AUTHORAVATAR_CONTAINER}>
        {avatarImage && (
            <GatsbyImage
                image={avatarImage}
                className={authorImage}
                alt="author-img"
                data-test={testAttributes.AUTHORAVATAR_IMAGE}
            />
        )}
    </div>
);
AuthorAvatar.propTypes = {
    /** avatarImage, Imagen-Avatar from author (gatsbyImageData) */
    avatarImage: PropTypes.objectOf(PropTypes.any),
};

AuthorAvatar.defaultProps = {
    avatarImage: undefined,
};
export default AuthorAvatar;
